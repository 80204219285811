// Variables

$white: #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #2e4a6b !default;

$primary: #3492fe !default;

// saddle brown #414C09
// dark green #1F2707
// light grey #CED6CE
// light blue #8BBDD4
// olive drab #807824
