@charset "UTF-8";
body,
html {
  width: 100%;
}

body {
  font-family: 'Nunito', 'Helvetica Neue', Arial, sans-serif;
}

hr,
hr.primary {
  max-width: 50px;
  border-color: #3492fe;
  background-color: #3492fe;
  height: 3px;
}

hr.light {
  border-color: #ffffff;
  background-color: #ffffff;
}

a {
  color: #3492fe;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  color: #3392fe;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.bg-primary {
  background-color: #3492fe !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

section {
  padding: 4rem 0;
}

section*#quote {
  padding: 8rem 0;
}

.section-heading {
  margin-top: 0;
  text-transform: uppercase;
}

::-moz-selection {
  color: #ffffff;
  background: #212529;
  text-shadow: none;
}

::selection {
  color: #ffffff;
  background: #212529;
  text-shadow: none;
}

img::selection {
  color: #ffffff;
  background: transparent;
}

img::-moz-selection {
  color: #ffffff;
  background: transparent;
}

#mainNav {
  border-bottom: 1px solid rgba(33, 37, 41, 0.1);
  background-color: #ffffff;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

#mainNav .navbar-brand {
  font-weight: 700;
  text-transform: uppercase;
  color: #3492fe;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

#mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #3392fe;
}

#mainNav .navbar-brand > img {
  max-width: 160px;
}

#mainNav .navbar-nav > li.menu-item a,
#mainNav .navbar-nav > li.menu-item a:focus {
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #212529;
}

#mainNav .navbar-nav > li.menu-item a:hover,
#mainNav .navbar-nav > li.menu-item a:focus:hover {
  color: #3492fe;
}

#mainNav .navbar-nav > li.menu-item a.active,
#mainNav .navbar-nav > li.menu-item a:focus.active {
  color: #3492fe !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li.menu-item a.active:hover,
#mainNav .navbar-nav > li.menu-item a:focus.active:hover {
  background-color: transparent;
}

@media (max-width: 768px) {
  #mainNav {
    border: none;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: transparent;
  }
  #mainNav .navbar-brand {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
    color: #ffffff;
  }
  #mainNav .navbar-nav > li.menu-item > a {
    padding: 0.5rem 1rem;
  }
  #mainNav .navbar-nav > li.menu-item > a,
  #mainNav .navbar-nav > li.menu-item > a:focus,
  #mainNav .menu-item-has-children:after {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-nav > li.menu-item > a:hover,
  #mainNav .navbar-nav > li.menu-item > a:focus:hover,
  #mainNav .menu-item-has-children:after:hover {
    color: #ffffff;
  }
  #mainNav.navbar-shrink {
    border-bottom: 1px solid rgba(33, 37, 41, 0.1);
    background-color: #ffffff;
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #3492fe;
  }
  #mainNav.navbar-shrink .navbar-brand:focus, #mainNav.navbar-shrink .navbar-brand:hover {
    color: #3392fe;
  }
  #mainNav.navbar-shrink .navbar-nav > li.menu-item > a,
  #mainNav.navbar-shrink .navbar-nav > li.menu-item > a:focus,
  #mainNav.navbar-shrink .menu-item-has-children:after {
    color: #212529;
  }
  #mainNav.navbar-shrink .navbar-nav > li.menu-item > a:focus, #mainNav.navbar-shrink .navbar-nav > li.menu-item > a:hover,
  #mainNav.navbar-shrink .navbar-nav > li.menu-item > a:focus:focus,
  #mainNav.navbar-shrink .navbar-nav > li.menu-item > a:focus:hover,
  #mainNav.navbar-shrink .menu-item-has-children:after:focus,
  #mainNav.navbar-shrink .menu-item-has-children:after:hover {
    color: #3492fe;
  }
  #mainNav ul.sub-menu {
    position: absolute;
    background-color: #ffffff;
    border-radius: 4px;
    left: 20px;
    list-style: none;
    width: 200px;
    padding-left: 0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }
  #mainNav ul.sub-menu li {
    padding: 8px;
  }
  #mainNav ul.sub-menu li ul.sub-menu {
    left: 100%;
    top: 0;
  }
}

@media (min-width: 768px) {
  ul.sub-menu {
    display: none;
  }
  .menu-item-has-children {
    position: relative;
  }
  .menu-item-has-children:after {
    content: '▸';
    color: rgba(255, 255, 255, 0.7);
    transform: rotate(90deg);
    display: inline-block;
    margin-left: -15px;
  }
  .menu-item-has-children:focus > ul.sub-menu li.menu-item-has-children:after, .menu-item-has-children:hover > ul.sub-menu li.menu-item-has-children:after, .menu-item-has-children:active > ul.sub-menu li.menu-item-has-children:after {
    content: '▸';
    color: #212529;
    transform: rotate(0);
    display: inline-block;
    margin-left: 0;
  }
  .menu-item-has-children:focus > ul.sub-menu li:focus, .menu-item-has-children:focus > ul.sub-menu li:hover, .menu-item-has-children:focus > ul.sub-menu li:active, .menu-item-has-children:hover > ul.sub-menu li:focus, .menu-item-has-children:hover > ul.sub-menu li:hover, .menu-item-has-children:hover > ul.sub-menu li:active, .menu-item-has-children:active > ul.sub-menu li:focus, .menu-item-has-children:active > ul.sub-menu li:hover, .menu-item-has-children:active > ul.sub-menu li:active {
    background-color: rgba(33, 37, 41, 0.1);
  }
  .menu-item-has-children:focus > ul.sub-menu, .menu-item-has-children:hover > ul.sub-menu, .menu-item-has-children:active > ul.sub-menu {
    display: block;
  }
  .menu-item-has-children:after {
    content: '▸';
    transform: rotate(90deg);
    display: inline-block;
    margin-left: -12px;
  }
}

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);
  background-image: url("assets/img/header.jpg");
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

header.masthead:after {
  content: ' ';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

header.masthead > .container {
  position: relative;
  z-index: 3;
}

header.masthead hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

header.masthead h1 {
  font-size: 2rem;
}

header.masthead p {
  font-weight: 300;
}

@media (min-width: 768px) {
  header.masthead p {
    font-size: 1.15rem;
  }
}

@media (min-width: 992px) {
  header.masthead {
    height: 80vh;
    padding-top: 0;
    padding-bottom: 0;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 4rem;
  }
}

.service-box {
  max-width: 400px;
}

.text-primary {
  color: #3492fe !important;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 300px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.btn-xl {
  padding: 1rem 2rem;
}

.btn-primary {
  background-color: #3492fe;
  border-color: #3492fe;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #ffffff;
  background-color: #1b84fe !important;
}

.btn-primary:active, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
}

.card {
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid #3492fe;
}

html,
body {
  overflow-x: hidden;
}

.bg-faded {
  position: relative;
}

.bg-faded > div {
  position: relative;
  z-index: 2;
}

.bg-faded:after {
  content: ' ';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 51, 105, 0.7);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 51, 105, 0.7) 75%, #3492fe 100%);
  z-index: 1;
}

#principals .pwb-all-brands {
  width: 100%;
}

#principals .pwb-brands-cols-outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#principals .pwb-brands-cols-outer > div {
  filter: grayscale(1);
  transition: filter 0.4s ease-in-out;
}

#principals .pwb-brands-cols-outer > div:hover {
  filter: grayscale(0);
}

.icon-circle {
  padding: 24px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}
