// Styling for the masthead
header.masthead {
    padding-top: 10rem;
    padding-bottom: calc(10rem - 56px);
    background-image: url('assets/img/header.jpg');
    background-position: center center;
    @include background-cover;
    position: relative;
    &:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
        // background: linear-gradient(
        //   180deg,
        //   rgba(0, 0, 0, 0.5) 0%,
        //   rgba(0, 51, 105, 0.7) 75%,
        //   $primary 100%
        // );
        z-index: 1;
    }

    // &:before {
    //   // background-color: rgb(240, 14, 46);
    //   background-color: $white;
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   mix-blend-mode: darken;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }

    // &:after {
    //   // background-color: rgb(25, 37, 80);
    //   background-color: $primary;
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   mix-blend-mode: lighten;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }

    > .container {
        position: relative;
        z-index: 3;
    }
    hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    h1 {
        font-size: 2rem;
    }
    p {
        font-weight: 300;
    }
    @media (min-width: 768px) {
        p {
            font-size: 1.15rem;
        }
    }
    @media (min-width: 992px) {
        height: 80vh;
        // min-height: 650px;
        padding-top: 0;
        padding-bottom: 0;
        h1 {
            font-size: 3rem;
        }
    }
    @media (min-width: 1200px) {
        h1 {
            font-size: 4rem;
        }
    }
}
