// Styling for the navbar
#mainNav {
    border-bottom: 1px solid fade-out($gray-900, 0.9);
    background-color: $white;
    @include sans-serif-font;
    @include transition-all;
    .navbar-brand {
        font-weight: 700;
        text-transform: uppercase;
        color: $primary;
        @include sans-serif-font;
        &:focus,
        &:hover {
            color: darken($primary, 0.1);
        }
        > img {
            max-width: 160px;
        }
    }
    .navbar-nav {
        > li.menu-item {
            a,
            a:focus {
                font-size: 0.9rem;
                font-weight: 700;
                text-transform: uppercase;
                color: $gray-900;
                &:hover {
                    color: $primary;
                }
                &.active {
                    color: $primary !important;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        border: none;
        box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
    }
    @media (min-width: 992px) {
        border-color: transparent;
        background-color: transparent;
        .navbar-brand {
            color: fade-out($white, 0.3);
            &:focus,
            &:hover {
                color: $white;
            }
        }
        .navbar-nav > li.menu-item > a {
            padding: 0.5rem 1rem;
        }
        .navbar-nav > li.menu-item > a,
        .navbar-nav > li.menu-item > a:focus,
        .menu-item-has-children:after {
            color: fade-out($white, 0.3);
            &:hover {
                color: $white;
            }
        }
        &.navbar-shrink {
            border-bottom: 1px solid fade-out($gray-900, 0.9);
            background-color: $white;
            .navbar-brand {
                color: $primary;
                &:focus,
                &:hover {
                    color: darken($primary, 0.1);
                }
            }
            .navbar-nav > li.menu-item > a,
            .navbar-nav > li.menu-item > a:focus,
            .menu-item-has-children:after {
                color: $gray-900;
                &:focus,
                &:hover {
                    color: $primary;
                }
            }
        }

        ul.sub-menu {
            position: absolute;
            background-color: $white;
            border-radius: 4px;
            left: 20px;
            list-style: none;
            width: 200px;
            padding-left: 0;
            box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
            li {
                padding: 8px;
                ul.sub-menu {
                    left: 100%;
                    top: 0;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    ul.sub-menu {
        display: none;
    }
    .menu-item-has-children {
        position: relative;
        &:after {
            content: '▸';
            color: fade-out($white, 0.3);
            transform: rotate(90deg);
            display: inline-block;
            margin-left: -15px;
        }
        &:focus,
        &:hover,
        &:active {
            > ul.sub-menu {
                li {
                    &.menu-item-has-children {
                        &:after {
                            content: '▸';
                            color: $gray-900;
                            transform: rotate(0);
                            display: inline-block;
                            margin-left: 0;
                        }
                    }
                    &:focus,
                    &:hover,
                    &:active {
                        background-color: fade-out($gray-900, 0.9);
                    }
                }
            }
        }
    }
    .menu-item-has-children {
        &:focus,
        &:hover,
        &:active {
            > ul.sub-menu {
                display: block;
            }
        }
        &:after {
            content: '▸';
            transform: rotate(90deg);
            display: inline-block;
            margin-left: -12px;
        }
    }
}
