@import 'variables.scss';
@import 'mixins.scss';
@import 'global.scss';
@import 'navbar.scss';
// @import 'navbar-storefront.scss';
@import 'masthead.scss';
@import 'services.scss';
// @import 'portfolio.scss';
@import 'bootstrap-overrides.scss';

html,
body {
    overflow-x: hidden;
}
// #quote {
//     background-image: url(assets/img/bg.jpg);
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-attachment: fixed;
// }
.bg-faded {
    position: relative;
    > div {
        position: relative;
        z-index: 2;
    }
    &:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 51, 105, 0.7);
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 51, 105, 0.7) 75%,
            $primary 100%
        );
        z-index: 1;
    }
}

#principals {
    .pwb-all-brands {
        width: 100%;
    }
    .pwb-brands-cols-outer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > div {
            filter: grayscale(1);
            transition: filter 0.4s ease-in-out;
            &:hover {
                filter: grayscale(0);
            }
        }
    }
}

.icon-circle {
    padding: 24px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
}
